<template>
  <div class="cln" :class="{ 'mobile': !isDesktop, 'premium': user && user.group !== 1 }">
    <Mobile v-if="!isDesktop" />

    <MovieAds v-if="(!user || user.group === 1)" />

    <div v-if="isDesktop" v-show="showVideoAd" class="videoroll">
      <div class="close">
        <span v-if="videoAdCounter">Можно закрыть через {{ videoAdCounter }}</span>
        <i v-else @click="showVideoAd = !showVideoAd" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-6457457-3"></div>
      </div>
    </div>

    <p class="ad">
      А еще у нас много классных аниме на русском, заходи смотри
      <a href="https://1xanime.ru" target="_blank">1xanime.ru</a>
      бесплатно в HD.
    </p>

    <template v-if="!user || user.group === 1">
      <div id="yandex_rtb_R-A-6457457-4" class="rsya-block" style="margin: 20px auto 0px;"></div>
      <!-- <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Убрать рекламу
      </nuxt-link> -->
    </template>

    <nuxt />

    <template v-if="!user || user.group === 1">
      <div id="yandex_rtb_R-A-6457457-4-2" class="rsya-block" style="margin: 0px auto 60px;"></div>
      <!-- <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Убрать рекламу
      </nuxt-link> -->
    </template>

    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue'),
    MovieAds: () => import('~/components/MovieAds.vue'),
  },
  watch: {
    changeRoute(val) {
    },
  },
  computed: {
    ...mapState(['error', 'user', 'isDesktop']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      showVideoAd: false,
      videoAdCounter: 15,
      videoAdInterval: null,
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.lazyLoadImgs)
    // this.lazyLoadImgs()

    this.showAds()
  },
  methods: {
    showAds() {
      if (!this.user || this.user.group === 1) {
        setInterval(() => {
          this.showVideoAd = true
        }, 5 * 60 * 1000)

        this.videoAdInterval = setInterval(() => {
          if (this.videoAdCounter > 0) {
            --this.videoAdCounter
          }
        }, 1000)

        this.showVideoAd = true
        
        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-6457457-4',
      blockId: 'R-A-6457457-4'
    })
    })`
          document.body.appendChild(rsyaHorizon)

          const rsyaHorizon2 = document.createElement('script')
          rsyaHorizon2.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-6457457-4-2',
      blockId: 'R-A-6457457-4',
      pageNumber: 2,
    })
    })`
          document.body.appendChild(rsyaHorizon2)

          if (!this.isDesktop) {
            const rsyaFooterMobile = document.createElement('script')
            rsyaFooterMobile.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'floorAd',
      blockId: 'R-A-6457457-2'
    })
    })`
            document.body.appendChild(rsyaFooterMobile)
            
            const rsyaFullscreen = document.createElement('script')
            rsyaFullscreen.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'fullscreen',
      blockId: 'R-A-6457457-1'
    })
    })`
            // document.body.appendChild(rsyaFullscreen)
          } else {
            const rsyaDesktopVideoAd = document.createElement('script')
            rsyaDesktopVideoAd.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-6457457-3',
      blockId: 'R-A-6457457-3'
    })
    })`
            document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 200)
      }
    },
  }
}
</script>

<style lang="stylus">
p.ad
  padding: 70px 20px 10px
  color: #ddd
  background-color: rgba(255, 255, 255, 0.1)
  a
    color: orange
    &:hover
      color: red
</style>
